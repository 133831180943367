import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Moment from 'react-moment';
import { graphql } from 'gatsby';
import { RichText, Elements} from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Layout from "components/Layout";
import Newsletter from "components/newsletter";




const linkResolver = (doc) => {
// Pretty URLs for known types
if (doc.type === 'post') return `/articles/${doc.uid}`
if (doc.type === 'project') return `/projects/${doc.uid}`
if (doc.type === 'page') return `/page/${doc.uid}`
// Fallback for other types, in case new custom types get created
return `/doc/${doc.id}`
}

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {

  var props = {};
  switch(type) {

    // Add a class to paragraph elements
    // case Elements.paragraph:
    //   props = {className: 'paragraph-class'};
    //   return React.createElement('p', propsWithUniqueKey(props, key), children);
    //
    // Don't wrap images in a <p> taglinkResolver
    // case Elements.image:
    //   props = { src: element.url , alt: element.alt || '' };
    //   return React.createElement('img', propsWithUniqueKey(props, key));
    //
    // Add a class to hyperlinks
    case Elements.hyperlink:
      const targetAttr = element.data.target ? { target: element.data.target } : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign({
        className: 'link-class',
        rel: element.data.url ? 'noopener noreferrer' : '',
        target: element.data.url ? 'blank' : '',
        href: element.data.url || linkResolver(element.data)
      }, targetAttr, relAttr);
      return React.createElement('a', propsWithUniqueKey(props, key), children);

    // Return null to stick with the default behavior
    default:
      return null;
  }
}

const PostHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        width: 100%;
    }
`

const PostHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const PostCategory = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
    color: ${colors.grey600};

    h5 {
        margin-top: 0;
        margin-bottom: 1em;
    }
`

const PostTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const PostBody = styled("div")`
    max-width: 100%;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }

    }
    pre {
font-family: 'Inter var', sans-serif;
font-size: 24px;
font-style: italic;
line-height: 1.6;
text-align: center;
white-space: normal;
    }

    .Highlight1 { color: ${colors.blue600};    background-color: ${colors.blue200}; padding:2px;
    &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.blue500};
        background-color: inherit;
      }
  }
    .Highlight2 {color: ${colors.orange600};  background-color: ${colors.orange200}; padding:2px;
    &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.orange500};
        background-color: inherit;
      }}
    .Highlight3 { color: ${colors.purple600};  background-color: ${colors.purple200}; padding: 2px;&:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.purple500};
        background-color: inherit;
      }}
    .Highlight4 { color: ${colors.green600};   background-color: ${colors.green200}; padding: 2px; &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.green500};
        background-color: inherit;
      }}
    .Highlight5 { color: ${colors.teal600};    background-color: ${colors.teal200}; padding: 2px; &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.teal500};
        background-color: inherit;
      }}
      a.link-class {
        background-color: ${colors.pink100} !important;
        text-decoration: none;
        padding: 2px;
        position: relative;
         color: ${colors.pink700};



      }

`

const PostMetas = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    justify-content: space-between;
    font-size: 0.85em;
    color: ${colors.grey600};
`

const PostAuthor = styled("div")`
    margin: 0;
`

const PostDate = styled("div")`
    margin: 0;
`

const Post = ({ post, meta }) => {

    return (
        <>
        <Helmet>
            <title>{post.post_title[0].text}</title>
            <meta name="description" content={post.post_preview_description[0].text} />
            <meta name="og:title" content={post.post_title[0].text} />
            <meta name="og:description" content={post.post_preview_description[0].text} />
            <meta name="og:type" content='website' />
            <meta name="twitter:card" content='summary' />
            <meta name="twitter:creator" content='@kobyof' />
            {post.post_hero_image &&
            <meta name="twitter:image" content={post.post_hero_image.url} />
              }
            <meta name="twitter:title" content={post.post_title[0].text} />
            <meta name="twitter:description" content={post.post_preview_description[0].text} />
        </Helmet>

            <Layout>
                <PostCategory>
                    {RichText.render(post.post_category)}
                </PostCategory>
                <PostTitle>
                    {RichText.render(post.post_title)}
                </PostTitle>
                <PostMetas>
                    <PostAuthor>
                        {post.post_author}
                    </PostAuthor>
                    <PostDate>
                        <Moment format="MMMM D, YYYY">{post.post_date}</Moment>
                    </PostDate>
                </PostMetas>
                    {post.post_hero_image && (
                    <PostHeroContainer>
                        {post.post_category[0].text==='books' &&
                        <img style={{width:'fit-content', margin:'auto'}} src={post.post_hero_image.url} alt="bees" />
                        }
                        {post.post_category[0].text!=='books' &&
                        <img src={post.post_hero_image.url} alt="bees" />
                        }
                        <PostHeroAnnotation>
                            {RichText.render(post.post_hero_annotation)}
                        </PostHeroAnnotation>
                    </PostHeroContainer>
                )}
                <PostBody>
                <RichText render={post.post_body} htmlSerializer={htmlSerializer} />
                </PostBody>
                <br />
                <Newsletter />

            </Layout>
        </>
    )
}

export default ({ data }) => {
    const postContent = data.prismic.allPosts.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Post post={postContent} meta={meta}/>
    )
}

Post.propTypes = {
    post: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query PostQuery($uid: String) {
        prismic {
            allPosts(uid: $uid) {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_hero_annotation
                        post_date
                        post_category
                        post_body
                        post_author
                        post_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
